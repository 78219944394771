<template>
	<div class="hd-core-landing-page">
		<div class="hd-core-landing-page__title">
			The page you're looking for cannot be found.
		</div>
		<router-link 
			class="hd-core-landing-page__link"
			to="/"
			tag="div"
		>
			Back to Homepage
		</router-link>
	</div>
</template>

<style lang="scss" scoped>
	.hd-core-landing-page {
		@include flex-center();
		@include fit-window();
		flex-direction: column;
		text-align: center;

		background: $white;

		&__title {
			font-size: em(18);
		}

		&__link {
			text-decoration: none;
			text-transform: uppercase;
			font-size: em(12);
			margin: 2em 0;

			color: $primary;
		}
	}
</style>